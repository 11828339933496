<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h6>ABOUT</h6>
          <p>
            The website provides convenient access to comprehensive, accurate
            and efficient prediction of GDM profile.
          </p>
          <br />
          <p>
            Xiangya School of Pharmaceutical Sciences, Central South University.
            All rights reserved.
          </p>
        </div>
        <div class="col-md-3" style="margin-top: 20px">
          <a href=" " title="Visit tracker"
            ><img
              src="//clustrmaps.com/map_v2.png?cl=070000&w=300&t=tt&d=OZoQ31Ec5H5TvzRpA7EGOVVucGQzhXegr7HQNby1ajM&co=eeeeee&ct=0d470c"
            />
          </a>
        </div>

        <div class="col-md-5">
          <p class="copyright-text text-right">
            <i class="el-icon-message"></i> E-mail: jiedong@csu.edu.cn<br /><br />
            Copyright © 2022 All Rights Reserved by Artificial Intelligence &
            Drug Design Group.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>